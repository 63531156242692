import get from 'lodash/get';

import { FormattedFragment, Fragment, FragmentType } from '../types';
import { getLinkHref } from 'shared/helpers/uri';
import { createTemplate } from 'shared/helpers/template';
import { propsFormatter as formPropsFormatter } from 'shared/blocks/form';

const formatFragment = ({
  fragment,
  domainUrl,
  zone
}: {
  fragment: Fragment;
  domainUrl: string;
  zone: string;
}): FormattedFragment | undefined => {
  if (fragment.type === FragmentType.button) {
    return {
      type: FragmentType.button,
      content: {
        label: fragment.content.label,
        link: getLinkHref(fragment.content.link)
      }
    };
  }

  if (fragment.type === FragmentType.text) {
    return fragment;
  }

  if (fragment.type === FragmentType.image) {
    const link = getLinkHref(get(fragment, 'link', '')).replace(
      'http:',
      'https:'
    );

    return {
      type: FragmentType.image,
      url: getLinkHref(get(fragment, 'content.link', '')),
      alt: get(fragment, 'content.description', ''),
      link: createTemplate(link)({ domain: domainUrl })
    };
  }
  if (fragment.type === FragmentType.video) {
    return {
      type: FragmentType.video,
      videoUrl: getLinkHref(get(fragment, 'content.video.link', '')),
      posterUrl: getLinkHref(get(fragment, 'content.poster.link', ''))
    };
  }
  if (fragment.type === FragmentType.videoExternal) {
    return {
      type: FragmentType.videoExternal,
      ...fragment.content.video
    };
  }
  if (fragment.type === FragmentType.form) {
    return {
      type: FragmentType.form,
      content: formPropsFormatter(
        {
          template: null,
          title: fragment.title,
          currentZone: zone.toUpperCase(),
          form: fragment.content.form,
          validButton: fragment.submitButton,
          attachmentsCustomer: fragment.files,
          mailRecipient: fragment.mailRecipient,
          apis: fragment.apis
        },
        {
          getLinkHrefWithAuthentication: getLinkHref
        }
      )
    };
  }
};

export default formatFragment;
