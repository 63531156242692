import { QuestionType } from 'shared/components/presentational/Form';
import { Media, Link, Api } from 'shared/types';

export enum FragmentType {
  text = 'text',
  image = 'image',
  video = 'video',
  videoExternal = 'external_video',
  form = 'form',
  button = 'button'
}

export interface ButtonFragment {
  type: FragmentType.button;
  content: {
    label: string;
    link: Link;
  };
}

export interface TextFragment {
  title: string;
  type: FragmentType.text;
  content: string;
}

export interface ImageFragment {
  title: string;
  type: FragmentType.image;
  content: Media;
  link: Link | null;
}

export interface VideoFragment {
  title: string;
  type: FragmentType.video;
  content: {
    video: Media;
    poster: Media;
  };
}

export interface VideoExternalFragment {
  title: string;
  type: FragmentType.videoExternal;
  content: {
    video:
      | { url: string }
      | { video_id: string; provider: 'youtube' | 'dailymotion' };
  };
}

export interface FormFragment {
  type: FragmentType.form;
  title: string;
  content: {
    form: {
      name: string;
      category: string;
      questions: QuestionType[];
    };
  };
  submitButton: {
    label: string;
  } | null;
  files: { filename: string; url: string }[];
  mailRecipient: string | null;
  apis: {
    np6: Api;
    smartfaq?: Api;
  };
}

export type Fragment =
  | TextFragment
  | ImageFragment
  | VideoFragment
  | VideoExternalFragment
  | FormFragment
  | ButtonFragment;

export interface FormattedTextFragment {
  type: FragmentType.text;
  content: string;
}

export interface FormattedImageFragment {
  type: FragmentType.image;
  url: string;
  alt: string | null;
  link?: string;
}

export interface FormattedVideoFragment {
  type: FragmentType.video;
  videoUrl: string;
  posterUrl: string;
}

export type FormattedVideoExternalFragment = {
  type: FragmentType.videoExternal;
} & (
  | { url: string }
  | { provider: 'youtube' | 'dailymotion'; video_id: string }
);

export interface FormattedFormFragment {
  type: FragmentType.form;
  content: {
    template?: 'V1' | 'V2';
    formId: string;
    formCategory: string;
    title: string;
    subtitle?: string;
    questions: QuestionType[];
    submitButtonLabel: string | null;
    mailRecipient: string | null;
    withMailing: boolean;
    attachmentsCustomer: { filename: string; url: string }[] | null;
    apiUrl: Api['url'];
    apiMethod: Api['method'];
    faqUrlTemplate: (params?: { [param: string]: string }) => string;
    zone: string | null;
  };
}

export interface FormattedButtonFragment {
  type: FragmentType.button;
  content: {
    label: string;
    link: string;
  };
}

export type FormattedFragment =
  | FormattedTextFragment
  | FormattedImageFragment
  | FormattedVideoFragment
  | FormattedVideoExternalFragment
  | FormattedFormFragment
  | FormattedButtonFragment;

export interface FormattedArticleContent {
  id: number | null;
  title: string | null;
  link?: string | null;
  subtitle: string | null;
  slug: string;
  mainMedia: string;
  responsiveMedia: string;
  alt?: string;
  fragments: FormattedFragment[];
}

export interface ArticleContent {
  articleId: number;
  title: string;
  subtitle: null | string;
  slug: string;
  mainMedia: Media;
  responsiveMedia: Media | null;
  fragments: Fragment[];
  category: {
    id: number;
    name: string;
  };
}
