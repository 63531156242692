import React from 'react';

import {
  Wrapper,
  ContentWrapper,
  Picture,
  PictureShadow,
  PictureWrapper
} from './styles/components';
import Title from 'shared/components/presentational/Title';
import { getFormatedBannerUrlByDevice } from 'shared/blocks/one-shop/offer-highlight/helpers/getFormatedBannerUrlByDevice';
import { Button } from 'shared/blocks/one-shop/shared/components/Button/Button';
import { BannerUrlByDevice } from 'shared/types';

export type BannerProps = {
  title: string;
  description: string | null;
  bannerUrlByDevice: BannerUrlByDevice;
  primaryButton: {
    label: string;
    link: string;
    target: '_blank' | '_self';
  } | null;
};

export const Banner = ({
  title,
  description,
  primaryButton,
  bannerUrlByDevice
}: BannerProps) => {
  return (
    <Wrapper>
      <PictureWrapper>
        <PictureShadow></PictureShadow>
        <Picture
          responsivesUrls={getFormatedBannerUrlByDevice(bannerUrlByDevice)}
        />
      </PictureWrapper>
      <ContentWrapper>
        <Title subtitle={description} titleHtmlTag="h1" titleLabel={title} />

        {primaryButton ? (
          <Button
            fullWidthMobile
            label={primaryButton.label}
            link={primaryButton.link}
            variant="primary"
            target={primaryButton.target}
          />
        ) : null}
      </ContentWrapper>
    </Wrapper>
  );
};
