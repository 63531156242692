import {
  BannerTab,
  CardTab,
  Content,
  FormattedBannerTab,
  FormattedCardTab,
  FormattedProps,
  TEMPLATES
} from './types';
import { EngagementUnit } from '../shared/types';
import { getLinkHref } from 'shared/helpers/uri';
import { PropsFormatterUtils } from 'shared/types';
import { BREAKPOINTS } from 'shared/components/presentational/Picture/constants';

export default (
  content: Content,
  { getLinkHrefWithAuthentication }: PropsFormatterUtils
): FormattedProps => {
  const formatBanner = (tab: BannerTab): FormattedBannerTab => ({
    template: tab.template,
    tabTitle: tab.tab_title,
    autoPlaySpeed: tab.autoPlaySpeed ?? null,
    contents: tab.products.map(product => ({
      productId: product.product_id,
      title: product.title,
      description: product.description,
      primaryButton:
        product.primary_button?.label && product.primary_button?.link
          ? {
              label: product.primary_button.label,
              link: getLinkHrefWithAuthentication(product.primary_button.link),
              target:
                product.primary_button.link.rel === 'external'
                  ? '_blank'
                  : '_self'
            }
          : null,
      bannerUrlByDevice: {
        desktop: {
          url: getLinkHref(product.media.desktop_banner.link || ''),
          alt: product.media.desktop_banner.description || '',
          w: BREAKPOINTS.DESKTOP + 1,
          isVideo: false
        },
        tablet: {
          url: getLinkHref(
            product.media?.tablet_banner?.link ||
              product.media.desktop_banner.link
          ),
          alt:
            product.media?.tablet_banner?.description ||
            product.media?.desktop_banner?.description ||
            '',
          w: BREAKPOINTS.DESKTOP,
          isVideo: false
        },
        mobile: {
          url: getLinkHref(
            product.media?.mobile_banner?.link ||
              product.media?.tablet_banner?.link ||
              product.media.desktop_banner.link
          ),
          alt:
            product.media?.mobile_banner?.description ||
            product.media?.tablet_banner?.description ||
            product.media?.desktop_banner?.description ||
            '',
          w: BREAKPOINTS.PHABLET,
          isVideo: false
        },
        mobileMini: {
          url: getLinkHref(
            product.media?.mobile_banner?.link ||
              product.media?.tablet_banner?.link ||
              product.media.desktop_banner.link
          ),
          alt:
            product.media?.mobile_banner?.description ||
            product.media?.tablet_banner?.description ||
            product.media?.desktop_banner?.description ||
            '',
          w: BREAKPOINTS.PHONE,
          isVideo: false
        }
      }
    }))
  });

  const formatCard = (tab: CardTab): FormattedCardTab => ({
    template: tab.template,
    tabTitle: tab.tab_title,
    title: tab.title,
    subtitle: tab.subtitle,
    contents: tab.products.map(product => ({
      productId: product.productId,
      title: product.title,
      promotionalSticker: product.promotionalSticker || null,
      groups: product.groups.map(group => ({
        title: group.title,
        subtitle: group.subtitle || '',
        exponent: group.exponent,
        isMoreChannels: group.isMoreChannels,
        channels: group.channels.map(channel => ({
          label: channel.channel.media.description,
          href: getLinkHref(channel.channel.media.link)
        }))
      })),
      primaryButton:
        product.primaryButton?.label && product.primaryButton?.link
          ? {
              label: product.primaryButton.label,
              link: getLinkHrefWithAuthentication(product.primaryButton.link)
            }
          : null,
      secondaryButton:
        product.secondaryButton?.label && product.secondaryButton?.link
          ? {
              label: product.secondaryButton.label,
              link: getLinkHrefWithAuthentication(product.secondaryButton.link)
            }
          : null,
      moreChannelsIcon: {
        label: product.moreChannelsIcon?.description,
        href: product.moreChannelsIcon?.link.href
      },
      offerDetails: {
        availableCommitmentDurations: product.availableCommitmentDurations,
        commitmentConditions: product.commitmentConditions,
        engagementDuration: product.engagementDuration,
        engagementUnit: EngagementUnit[product.engagementUnit],
        netPrice: product.netPrice,
        price: product.price,
        priceInformation: product.priceInformation,
        promoDuration: product.promoDuration
      }
    }))
  });

  return {
    title: content.title,
    subtitle: content.subtitle,
    displayTabs: content.display_tabs,
    tabs: content.tabs.map(tab =>
      tab.template === TEMPLATES.banner ? formatBanner(tab) : formatCard(tab)
    )
  };
};
