import React from 'react';

import { StyledButton, Label, Wrapper } from './styles/components';

export const Button = ({
  label,
  link,
  type,
  fullWidthMobile,
  fullWidthDesktop,
  variant,
  onClick,
  target,
  ...props
}: {
  label: string;
  link?: string;
  type?: string;
  fullWidthMobile?: boolean;
  fullWidthDesktop?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  onClick?: () => void;
  target?: string;
}) => {
  return (
    <Wrapper {...props}>
      <StyledButton
        type={type}
        fullwidthmobile={fullWidthMobile}
        fullwidthdesktop={fullWidthDesktop}
        variant={variant}
        to={link}
        onClick={onClick}
        target={target}
      >
        <Label>{label}</Label>
      </StyledButton>
    </Wrapper>
  );
};
